<template>
  <div class="outpatient_container">
    <div class="header">
      <van-field readonly clickable label="选择就诊人" :value="patName" @click="patNamePickerShow = true"
        right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow" round position="bottom">
        <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
      </van-popup>
    </div>
    <div class="list">
      <div class="item" v-for="item in clinicPayFeeInfo" :key="item.orderNum" @click="detail(item.orderNum)">
        <van-image class="avatar" width="60" height="60" src="http://www.fkxrmyy.com/wxxcx/wx/image/doc.png"
          fit="contain" />
        <div class="info">
          <div>科室：{{ item.deptName }}</div>
          <div>医生：{{ item.doctorName }}</div>
          <div>就诊人：{{ item.patName }}</div>
          <div>就诊时间：{{ item.diagnTime }}</div>
          <div>门诊费用：{{ item.totalAmt }}元</div>
          <van-icon name="arrow" size="20px" />
        </div>
      </div>
    </div>
    <van-empty description="暂无内容" v-if="clinicPayFeeInfo === ''" />
    <div class="btn" v-if="patNamePickerShow === false">
      <van-button type="info" block @click="back">返&nbsp;回&nbsp;首&nbsp;页</van-button>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Outpatient",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      clinicPayFeeInfo: []
    };
  },
  created () {
    if (window.localStorage.getItem("openId") === null) {
      this.getCode();
    } else {
      this.getMembers();
    }
  },
  methods: {
    // 获取code
    getCode () {
      let local = window.location.href;
      let appid = "wxcdae494f7e9422e7";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent
        (local)
        }&response_type=code&scope=snsapi_base&state=123&#wechat_redirect`;
      // window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcdae494f7e9422e7&redirect_uri=http://tssywx.tssrmyy.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
      let str = window.location.href;
      let code = str.split("code=")[1].split("&")[0];
      this.code = code
      this.login(code);
    },
    //获取openId
    async login (code) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      let data = qs.stringify({
        code: code
      });
      const { data: res } = await formPost("/wxapp/login", data);
      this.$toast.clear();
      if (res.code === 0) {
        window.localStorage.setItem("openId", res.data.openid);
        this.getMembers();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name + '(' + res.data[i].pat_card_no + ')');
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.getClinicPayFeeInfo();
      }
      else {
        this.$toast.fail("暂无建档，请先建档");
        // setTimeout(() => {
        //   this.$router.push({
        //     path: "/file"
        //   });
        // }, 2000);
      }
    },
    //获取门诊待缴费列表
    async getClinicPayFeeInfo () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/getClinicPayFeeInfo",
        {
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.clinicPayFeeInfo = res.data.item;
      } else {
        this.clinicPayFeeInfo = "";
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getClinicPayFeeInfo();
    },
    detail (orderNum) {
      this.$router.push({
        path: "/outpatient/detail",
        query: {
          orderNum: orderNum,
          idCard: this.patNameArray[this.patNameIndex].card_no,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type
        }
      });
    },
    back () {
      this.$router.push({
        path: "/",
      });
    }
  }
};
</script>

<style scope>
.outpatient_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.outpatient_container .header .van-cell {
  border-bottom: 1px solid rgb(201, 191, 191);
  padding: 30px;
}

.outpatient_container .header .van-cell::after {
  border: 0;
}

.outpatient_container .header .van-field__label {
  text-align: left;
  font-size: 40px;
}

.outpatient_container .header .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.outpatient_container .header .van-icon {
  font-size: 40px;
}

.outpatient_container .header .van-picker-column {
  font-size: 40px;
}

.outpatient_container .header .van-picker__cancel,
.outpatient_container .header .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.outpatient_container .list {
  padding-bottom: 60px;
}

.outpatient_container .item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 30px;
}

.outpatient_container .item .info {
  position: relative;
  font-size: 36px;
}

.outpatient_container .item .info div {
  margin: 20px 0;
  margin-left: 20px;
}

.outpatient_container .item .info .van-icon {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}

.outpatient_container .btn {
  width: 100%;
  height: 90px;
  position: fixed;
  left: 0;
  bottom: 60px;
  z-index: 1;
  background-color: white;
}

.outpatient_container .btn .van-button {
  border-radius: unset !important;
  border: unset !important;
  font-size: 38px !important;
}
</style>